<template>
    <div class="_pos-rel"  style="background-color:rgba(247, 247, 247, 1);">
        <div class="" style="position:sticky;top:7.99087vh;z-index:2;">
            <div class="_flex-row-center-mid _pos-rel _mb-pad-y-25 _white _mb-font-32" style="background-color:rgba(66, 132, 220, 1);">
                <router-link :to="'/orderList'" class="_pos-abs _left _mb-pad-left-25 _flex-row-mid">
                    <img class="_mb-w-20 _mb-mar-right-10" src="~@/assets/image/hangda/fanhui1.png" alt="">
                    <div class="_mb-font-28">{{$t('返回')}}</div>
                </router-link>
                <div style="line-height:1;">{{$t('订单详情')}}</div>
            </div>
        </div>
        <div class="_white-bg _mb-pad-x-25 _mb-pad-y-30 _mb-mar-bottom-20">
            <div class="_mb-font-36 _b _mb-mar-bottom-35">{{$t('订单号')}}：{{order_info.ordersn}}</div>
            <div>
                <div class="_flex-row _pos-rel  _mb-pad-bottom-50">
                    <div class="_flex-col-mid  _mb-mar-right-25">
                        <div class="_mb-w-40 _white nav-tip _pos-rel _z-index-1 _mb-font-26" style="background-color:rgba(255, 132, 0, 1);">{{$t('寄')}}</div>
                        <div class="_pos-abs _top _h-max" style="border:1px dashed rgba(153, 153, 153, 1);"></div>
                    </div>
                    <div>
                        <div class="_flex-row-mid _mb-mar-bottom-15">
                            <div class="_b _mb-mar-right-20 _mb-font-32">{{lang === 'zh_cn' ?order_info.storehouse_name : order_info.storehouse_name_us}}</div>
                            <div class="_mb-font-28" style="color:rgba(66, 132, 220, 1);align-self:flex-end;">{{order_info.storehouse_phone}}</div>
                        </div>
                        <div class="_grey-99 _mb-font-28">{{order_info.storehouse_province+order_info.storehouse_city+order_info.storehouse_area+order_info.storehouse_address}}</div>
                    </div>
                </div>
                <div class="_flex-row">
                    <div class="  _mb-mar-right-25">
                        <div class="_mb-w-40 _white nav-tip _pos-rel _mb-font-26" style="background-color:rgba(66, 132, 220, 1);">{{$t('收')}}</div>
                    </div>
                    <div>
                        <div class="_flex-row-mid _mb-mar-bottom-15">
                            <div class="_b _mb-mar-right-20 _mb-font-32">{{order_info.nickname}}</div>
                            <div class="_mb-font-28" style="color:rgba(66, 132, 220, 1);align-self:flex-end;">{{order_info.mobile}}</div>
                            <span v-if="order_info.status < 90 && order_info.status > 0 && agentInfo.is_main" @click="openEdit" style="padding: 5px;border: 1px solid #cccccc;border-radius: 5px;margin-left: 5px;">修改地址</span>
                        </div>
                        <div class="_grey-99 _mb-font-28">{{order_info.area+order_info.province+order_info.address}}</div>
                    </div>
                </div>
            </div>
            <div @click="toCheck" class="_w-max _mb-h-75 _flex-row-center-mid _mb-font-28 _white _mb-mar-top-35" style="background-color:rgba(66, 132, 220, 1);border-radius:6px;">{{$t('查询物流')}}</div>
        </div>
        <div class="_white-bg _mb-pad-x-25 _mb-font-28 _mb-mar-bottom-20">
            <div class="_w-max _border-bottom _flex-row-mid _mb-pad-y-30">
                <div class="_mb-w-150 _grey-99">{{$t('状态')}}</div>
                <div class="_flex-1 _b">{{order_info.status_name}}</div>
            </div>
            <div @click="copy(order_info.express_sn)" class="_w-max _border-bottom _flex-row-mid _mb-pad-y-30">
                <div class="_mb-w-150 _grey-99">{{$t('物流单号')}}</div>
                <div class="_flex-1 _flex-row-mid">
                    <div class="_mb-mar-right-10">{{order_info.express_sn}}</div>
                    <img class="_mb-w-30" src="~@/assets/image/hangda/copy1.png" alt="">
                </div>
            </div>
            <div class="_w-max _border-bottom _flex-row-mid _mb-pad-y-30">
                <div class="_mb-w-150 _grey-99">{{$t('路线')}}</div>
                <div class="_flex-1 " v-if="lang == 'zh_cn'">{{order_info.express_name}}</div>
                <div class="_flex-1 " v-if="lang == 'ru'">{{order_info.express_name_ru}}</div>
                <div class="_flex-1 " v-if="lang == 'en_us'">{{order_info.express_name_us}}</div>
            </div>
            <div class="_w-max _border-bottom _flex-row-mid _mb-pad-y-30">
                <div class="_mb-w-150 _grey-99">{{$t('打包时间')}}</div>
                <div class="_flex-1">{{order_info._pack_time||''}}</div>
            </div>
            <div class="_w-max _border-bottom _flex-row-mid _mb-pad-y-30">
                <div class="_mb-w-150 _grey-99">{{$t('入库仓')}}</div>
                <div class="_flex-1">{{order_info.storehouse_name}}</div>
            </div>
            <div class="_w-max _border-bottom _flex-row-mid _mb-pad-y-30">
                <div class="_mb-w-150 _grey-99" style="flex-shrink: 0;">{{$t('包裹单号')}}</div>
                <div class="_flex-1" style="display: flex;align-items: center;column-gap: 1rem;flex-wrap: wrap;gap: 6px;">
                    <span v-for="(item,index) in order_info.package_list" :key="index" style="text-align: center;">
                        <div class="pack-num" @click="getReportInfo(item.expresssn)">{{ item.expresssn }}</div>
                        <img v-viewer v-for="(pic,key) in item.package_imgs" :key="key" style="width: 50px;height: 50px;" :src="pic"/>
                    </span>
                </div>
            </div>
            <div class="_w-max _border-bottom _flex-row-mid _mb-pad-y-30">
                <div class="_mb-w-150 _grey-99">{{$t('打包图片')}}</div>
                <div class="_flex-1 grid-box">
                    <div v-for="(item,index) in order_info.pack_img" :key="index" class="_w-max" style="aspect-ratio:1/1;height:auto;" >
                        <img class="_w-max _h-max" v-viewer style="border-radius:6px;" :src="item" alt="">
                    </div>
                </div>
            </div>
            <div class="_w-max _border-bottom _flex-row-mid _mb-pad-y-30">
                <div class="_mb-w-150 _grey-99">{{$t('运费')}}</div>
                <div class="_flex-1 _b" style="color:rgba(66, 132, 220, 1);">¥{{order_info.price}}</div>
            </div>
            <!-- <div class="_w-max _border-bottom _flex-row-mid _mb-pad-y-30">
                <div class="_mb-w-150 _grey-99">{{$t('支付凭证')}}</div>
                <div class="_flex-1">
                    <div class="_mb-w-110" style="aspect-ratio:1/1;height:auto;" >
                        <img class="_w-max _h-max" v-viewer style="border-radius:6px;" src="" alt="">
                    </div>
                </div>
            </div> -->
            <div class="_w-max  _mb-pad-y-20 _grey-99 _mb-font-24">
                <div class="_mb-mar-bottom-10">{{$t('创建时间')}}：{{order_info.add_time}}</div>
                <!-- <div class="_mb-mar-bottom-10">{{$t('打包时间')}}：{{order_info.confirm_time}}</div> -->
            </div>
            <div class="_w-max _border-bottom _flex-row-mid _mb-pad-y-30">
                <div class="_mb-w-150 _grey-99" style="flex-shrink: 0;">{{$t('大包单号')}}</div>
                <div class="_flex-1" style="display: flex;align-items: center;column-gap: 1rem;flex-wrap: wrap;gap: 6px;">
                    <span v-for="(item,index) in order_info.big_pack_list" :key="index" style="text-align: center;">
                        <div class="pack-num" @click="toTrack(item)">{{ item.tag_no }}</div>
                    </span>
                </div>
            </div>
        </div>
        <div class="_mb-pad-x-25 _mb-pad-y-25 _white-bg">
            <div class="_b _mb-font-36 _mb-mar-bottom-25">{{$t('打包信息')}}</div>
            <div class="page-bg _mb-pad-x-25 _mb-pad-y-35 _mb-mar-bottom-25" v-for="(item,index) in order_info.out_package_list" :key="index">
                <div class="_flex-row-mid _mb-font-32 _b _mb-mar-bottom-20">
                    <img class="_mb-w-35 _mb-mar-right-15" src="~@/assets/image/hangda/pack-icon.png" alt="">
                    <div>{{$t('打包单号')+(index+1)}}：{{item.pack_no}}</div>
                </div>
                <div class="_mb-font-28 _mb-mar-bottom-10" :style="{color:`${'rgba(76, 172, 82, 1)'}`}">{{$t('状态')}}：{{item.status_name}}</div>
                <div class="_mb-font-28 _mb-mar-bottom-10 _flex-row-mid">
                    <div class="_flex-2">{{$t('长')}}：{{item.length}}cm</div>
                    <div class="_flex-3">{{$t('宽度')}}：{{item.width}}cm</div>
                </div>
                <div class="_mb-font-28 _flex-row-mid">
                    <div class="_flex-2">{{$t('高')}}：{{item.height}}cm</div>
                    <div class="_flex-3">{{$t('体重')}}：{{item.weight}}cm</div>
                </div>
            </div>
        </div>
        <!-- 包裹详情 -->
		<Modal v-model="open_modal" footer-hide :mask-closable="false">
			<Form label-position="left" :label-width="100" class="fontsize">
				<Row>
					<Col span="12">
						<FormItem :label="$t('包裹单号')"><span style="font-size: 16px;">{{ reportInfo.expresssn }}</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('包裹重量')"><span style="font-size: 16px;">{{ reportInfo.weight}}kg</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('长宽高')" style="word-break: break-all;"><span style="font-size: 16px;">{{ reportInfo.length }}cm*{{reportInfo.width}}cm*{{reportInfo.height}}cm</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('报备时间')"><span style="font-size: 16px;">{{ reportInfo.report_time }}</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('入库时间')"><span style="font-size: 16px;">{{ reportInfo.enter_time }}</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('申报价值')"><span style="font-size: 16px;">{{ reportInfo.goods_money }}</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('物品类型')"><span style="font-size: 16px;">{{ reportInfo.goods_name }}</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('包裹状态')"><span style="font-size: 16px;">{{ reportInfo.status_name }}</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('包裹类型')">
							<span style="font-size: 16px;" v-if="lang == 'zh_cn'">{{ reportInfo.cate_name_zh }}</span>
							<span style="font-size: 16px;" v-if="lang == 'ru'">{{ reportInfo.cate_name_ru }}</span>
							<span style="font-size: 16px;" v-if="lang == 'en_us'">{{ reportInfo.cate_name_us }}</span>
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('到付金额')"><span style="font-size: 16px;">{{ reportInfo.collect_payment }}RMB</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('到付金额')"><span style="font-size: 16px;">{{ reportInfo.price }}USD</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('是否超大包裹')"><span style="font-size: 16px;">{{ reportInfo.is_big_report?$t('yes'):$t('no') }}</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('木架费')"><span style="font-size: 16px;">{{ reportInfo.reinforce_free }}</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('入库图片')">
							<div style="float: left;" v-viewer v-for="(val,indexs) in reportInfo.package_imgs" :key="indexs">
								<img style="width: 40px;height: 40px;" :src="val">
							</div>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
        <Modal v-model="open_edit" footer-hide>
			<div class="_white-bg _mb-font-28 _mb-mar-bottom-40" style="border-radius:6px;">
                <div class="_flex-row-bet-mid _border-bottom _mb-pad-y-35 _mb-pad-x-25">
                    <div class="_mb-w-150">{{$t('收件人')}}</div>
                    <input class=" _mb-font-28 _flex-1" v-model="formData.linkman" :placeholder="$t('请输入')" type="text">
                </div>
                <div class="_flex-row-bet-mid _border-bottom _mb-pad-y-35 _mb-pad-x-25">
                    <div class="_mb-w-150">{{$t('联系电话')}}</div>
                    <input class=" _mb-font-28 _flex-1" v-model="formData.mobile" :placeholder="$t('请输入')" type="text">
                </div>
                <div class="_flex-row-bet-mid _border-bottom _mb-pad-y-35 _mb-pad-x-25" >
                    <div class="_mb-w-150">{{$t('国家/地区')}}</div>
                    <div class="_flex-row-mid _pos-rel _flex-1" style="justify-content:flex-end;">
                        <div @click="openCountry" class=" _mb-font-28 _mb-mar-right-10 _flex-1"  type="text">{{formData.area||$t('请选择')}}</div>
                        <div @click="openCountry" class="iconfont2 icon-xiala"></div>
                        <div class="select-box" v-if="open_country">
                            <div class="li _line-1" v-for="(item,index) in country_list" :key="index" @click.stop="getCountrys(item)">{{item.address_name}}</div>
                        </div>
                    </div>
                </div>
                <div class="_flex-row-bet-mid _border-bottom _mb-pad-y-35 _mb-pad-x-25" >
                    <div class="_mb-w-150">{{$t('省/州')}}</div>
                    <div class="_flex-row-mid _pos-rel _flex-1" style="justify-content:flex-end;">
                        <div @click="openProvince" class=" _mb-font-28 _mb-mar-right-10 _flex-1"  type="text">{{formData.province||$t('请选择')}}</div>
                        <div @click="openProvince" class="iconfont2 icon-xiala"></div>
                        <div class="select-box" v-if="open_province">
                            <div class="li _line-1" v-for="(item,index) in province_list" :key="index" @click.stop="getProvinces(item)">{{item.label}}</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="_flex-row-bet-mid _border-bottom _mb-pad-y-35 _mb-pad-x-25">
                    <div class="_mb-w-150">{{$t('省/州')}}</div>
                    <input class=" _mb-font-28 _flex-1" v-model="formData.province" :placeholder="$t('请输入')" type="text">
                </div> -->
                <div class="_flex-row-bet-mid _border-bottom _mb-pad-y-35 _mb-pad-x-25">
                    <div class="_mb-w-150">{{$t('城市')}}</div>
                    <input class=" _mb-font-28 _flex-1" v-model="formData.city" :placeholder="$t('请输入')" type="text">
                </div>
                <div class="_flex-row-bet-mid _border-bottom _mb-pad-y-35 _mb-pad-x-25">
                    <div class="_mb-w-150">{{$t('邮编')}}</div>
                    <input class=" _mb-font-28 _flex-1" v-model="formData.zip_code" :placeholder="$t('请输入')" type="text">
                </div>
                <div class="_flex-row-bet-mid _mb-pad-y-25 _mb-pad-x-35">
                    <div class="_mb-w-150">{{$t('详细地址')}}</div>
                    <input class=" _mb-font-28 _flex-1" v-model="formData.address" :placeholder="$t('请输入')" type="text">
                </div>
            </div>
            <Button @click="addressSubmit" long type="primary">{{$t('修改地址')}}</Button>
		</Modal>
    </div>
</template>

<script>
import { userOrderInfo, getReportInfo, editShippingAddress, bannerList, getCountry, getProvinceOption } from '@/api/jiyun';
export default {
    name: 'MobileOrderdetail',
    data() {
        return {
            order_info:{},
            lang: 'zh_cn',
            open_modal: false,
            reportInfo: {},
            open_edit: false,
            formData: {
                linkman: '',
                mobile: '',
                area_id: '',
                area: '',
                province: '',
                city: '',
                address: '',
                zip_code: '',
            },
            country_list: [],
            open_country:false,
            open_province:false,
            is_check: false,
            agentInfo: {},
            province_list: [],
        };
    },
    mounted() {
        this.lang = localStorage.getItem('think-lang') || 'zh_cn';
        if(this.$route.query.id){
            this.getDetail(this.$route.query.id);
        }
        this.getCountry();
        this.bannerList();
    },
    methods: {
        // 物流轨迹
        toTrack(row){
            if(row.pack_date) row.pack_date = row.pack_date.slice(0,10);
            this.$router.push({
                path: '/search',
                query: {
                    expresssn: row.tag_no + '/' + row.pack_date
                }
            })
        },
        // 省州下拉
        openProvince(){
            if(!this.formData.area_id) return this.$Message.info(this.$t('请先选择国家或地区'));
            this.open_province = !this.open_province;
            this.open_country = false;
        },
        // 打开国家下拉
        openCountry(){
            this.open_country = !this.open_country;
            this.open_province = false;
        },
        // 选择城市
        getProvinces(province){
            this.open_province = false;
            this.formData.province = province.label;
        },
        // 代理banner
        bannerList(){
            let param = {
                domain_url: window.location.origin
            }
            bannerList(param).then((res)=>{
                this.agentInfo = res.data;
            }).catch((err)=>{
                this.$Message.error(err.msg)
            })
        },
        // 获取城市列表
        getProvince(){
            let param = {
                pid: this.formData.area_id
            }
            getProvinceOption(param).then((res)=>{
                this.province_list = res.data;
                if(this.province_list.length <= 0){
                    this.$Message.info(this.$t('请重新选择国家或地区'));
                    this.formData.area_id = 0;
                    this.formData.area = '';
                    this.formData.province = '';
                }
            }).catch((err)=>{
                this.$Message.error(err.msg)
            })
        },
        // 提交
        addressSubmit(){
            if(this.is_check) return;
            this.is_check = true;
            this.formData.order_id = this.order_info.id;
            editShippingAddress(this.formData).then((res)=>{
                this.is_check = false;
                this.open_edit = false;
                this.$Message.success(res.msg);
                this.getDetail();
            }).catch((err)=>{
                this.is_check = false;
                this.$Message.error(err.msg);
            })
        },
        // 编辑地址
        openEdit(){
            this.formData.linkman = this.order_info.linkman;
            this.formData.mobile = this.order_info.mobile;
            this.formData.area_id = this.order_info.area_id;
            this.formData.area = this.order_info.area;
            this.formData.province = this.order_info.province;
            if(this.formData.area_id) {
                let country = this.country_list.find(item => item.id == this.formData.area_id);
                if(!country){
                    this.$Message.info(this.$t('请重新选择国家或地区'));
                    this.formData.area_id = 0;
                    this.formData.area = '';
                    this.formData.province = '';
                }else{
                    this.getProvince();
                }
            }
            this.formData.city = this.order_info.city;
            this.formData.address = this.order_info.address;
            this.formData.zip_code = this.order_info.zip_code;
            this.open_edit = true;
        },
        // 选择国家
        getCountrys(country) {
            this.open_country = false;
            this.formData.area_id = country.id;
            this.formData.area = country.address_name;
            this.formData.province = '';
            this.formData.city = '';
            this.formData.address = '';
            this.getProvince();
        },
        // 获取国家列表
        getCountry(){
            getCountry().then((res)=>{
                this.country_list = res.data;
            }).catch((err)=>{
                this.$Message.error(err.msg)
            })
        },
        // 包裹详情
        getReportInfo(expresssn){
            let param = {
                expresssn: expresssn
            }
            getReportInfo(param).then((res)=>{
                this.reportInfo = res.data;
                this.open_modal = true;
            }).catch((err)=>{
                this.$Message.error(err.msg);
            })
        },
        getDetail(id){
            let param = {
                order_id: id,
                domain_url: window.location.origin
            }
            userOrderInfo(param).then((res)=>{
                this.order_info = res.data
            }).catch((err)=>{
                this.$Message.error(err.msg)
            })
        },
        toCheck(){
            this.$router.push({
                path: '/search',
                query:{
                    expresssn: '',
                }
            })
        },
        copy(text){
            this.$copyText(text).then(function(e){
                this.$Message.success(this.$t('复制成功'))
            }),function(e){
                this.$Message.error(this.$t('复制失败'))
            }
        },
    },
};
</script>

<style scoped>
textarea {
    outline: none;
    background: #F7F7F7;
}
.fontsize /deep/ .ivu-form-item-label {
    font-size: 16px;
}
.select-box {
    position: absolute;
    width: 100%;
    z-index: 3;
    right: 0;	
    top: 100%;
    background-color: #ffffff;
    overflow-y:scroll;
    min-height: 4.56621vh;
    max-height: 22.83105vh;
    overflow-x: hidden;
    box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
}
.select-box .li {
    width: 100%;
    height: 4.56621vh;
    padding: 0px 4vw;
    line-height: 4.56621vh;
    font-size: 3.73333vw;
}
.select-box .li:hover {
    border: 1px solid #D6D6D6;
}
.nav-tip{
    border-radius:4px;
    aspect-ratio:1/1;
    height:auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.grid-box{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 3.33333vw;
}
.page-bg{
    background-image: url('~@/assets/image/hangda/mb-packbg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 6px;
}
.pack-num {
    background: #4284DC;color: #fff;
    padding: 2px 8px;
    border-radius: 4px;
}
</style>